import $ from 'cash-dom'
import { register } from 'swiper/element/bundle'
register()

window.addEventListener('load', () => {
  const $newSwiper = document.querySelector('[data-component="swiper-lcp"]')

  if ($newSwiper) {
    $newSwiper.initialize()
    $($newSwiper).removeClass('hidden')
    $('.lcp-content').hide()
  }
})
